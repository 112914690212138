<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <h6 class="text-center">{{ trainingDescription }}</h6>
        <hr>
        <div class="w-100 text-center wrapper-training">
          <div class="training" :class="`formation-${teamFront.training}`">
            <img :src="bg" alt="Fondo de formación" class="w-100">
            <div class="team-image">
              <img :src="teamFront.image" :alt="teamFront.name" class="img-fluid">
            </div>
            <div class="team-name w-100">
              <h5 class="text-center border-text-black">{{ teamFront.name }}</h5>
            </div>
            <div v-for="(player, i) in gk" :key="`gk-${i}`" class="player gk">
              <player-training :tshirt="tshirt" :player="player" :console="console" :lang="lang"></player-training>
            </div>
            <div v-for="(player, i) in cb" :key="`cb-${i}`" class="player cb" :class="`cb-${i}`">
              <player-training :tshirt="tshirt" :player="player" :console="console" :lang="lang"></player-training>
            </div>
            <div v-for="(player, i) in lb" :key="`lb-${i}`" class="player lb">
              <player-training :tshirt="tshirt" :player="player" :console="console" :lang="lang"></player-training>
            </div>
            <div v-for="(player, i) in rb" :key="`rb-${i}`" class="player rb">
              <player-training :tshirt="tshirt" :player="player" :console="console" :lang="lang"></player-training>
            </div>
            <div v-for="(player, i) in cdm" :key="`cdm-${i}`" class="player cdm" :class="`cdm-${i}`">
              <player-training :tshirt="tshirt" :player="player" :console="console" :lang="lang"></player-training>
            </div>
            <div v-for="(player, i) in lm" :key="`lm-${i}`" class="player lm">
              <player-training :tshirt="tshirt" :player="player" :console="console" :lang="lang"></player-training>
            </div>
            <div v-for="(player, i) in rm" :key="`rm-${i}`" class="player rm">
              <player-training :tshirt="tshirt" :player="player" :console="console" :lang="lang"></player-training>
            </div>
            <div v-for="(player, i) in cam" :key="`cam-${i}`" class="player cam">
              <player-training :tshirt="tshirt" :player="player" :console="console" :lang="lang"></player-training>
            </div>
            <div v-for="(player, i) in st" :key="`st-${i}`" class="player st" :class="`st-${i}`">
              <player-training :tshirt="tshirt" :player="player" :console="console" :lang="lang"></player-training>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import PlayerTraining from '../partials/PlayerTraining'

export default {
  components: {
    PlayerTraining
  },
  data () {
    return {
      trainingDescription: '',
      tshirt: '',
      bg: '',
      gk: [],
      cb: [],
      lb: [],
      rb: [],
      cdm: [],
      cam : [],
      lm: [],
      rm: [],
      st: []
    }
  },
  computed: {
    ...mapGetters([
      'lang',
      'console',
      'teamFront'
    ])
  },
  mounted () {
    this.$store.dispatch('SET_MENU_PRIMARY_TEAM', 'home')
    this.fetchData()
  },
  methods: {
    fetchData () {
      const lang = this.$route.params.lang
      const cons = this.$route.params.console
      const slug = this.$route.params.teamSlug
      const path = `${lang}/console/${cons}/team/${slug}/training`
      this.$axios.get(path).then((response) => {
        const data = response.data
        this.tshirt = data.tshirt
        const emptyUser = {
          nick: 'Free'
        }
        const gk = data.players.filter(p => {
          return p.position === 'GK'
        })
        this.gk.push(gk[0] ? gk[0] : emptyUser)
        const cb = data.players.filter(p => {
          return p.position === 'CB'
        })
        this.cb.push(cb[0] ? cb[0] : emptyUser)
        this.cb.push(cb[1] ? cb[1] : emptyUser)
        const lb = data.players.filter(p => {
          return p.position === 'LB'
        })
        this.lb.push(lb[0] ? lb[0] : emptyUser)
        const rb = data.players.filter(p => {
          return p.position === 'RB'
        })
        this.rb.push(rb[0] ? rb[0] : emptyUser)
        const cdm = data.players.filter(p => {
          return p.position === 'CDM'
        })
        this.cdm.push(cdm[0] ? cdm[0] : emptyUser)
        if (Number(this.teamFront.training) !== 1) {
          this.cdm.push(cdm[1] ? cdm[1] : emptyUser)
        }
        if (Number(this.teamFront.training) !== 3) {
          const cam = data.players.filter(p => {
            return p.position === 'CAM'
          })
          this.cam.push(cam[0] ? cam[0] : emptyUser)
        }
        const rm = data.players.filter(p => {
          return p.position === 'RM'
        })
        this.rm.push(rm[0] ? rm[0] : emptyUser)
        const lm = data.players.filter(p => {
          return p.position === 'LM'
        })
        this.lm.push(lm[0] ? lm[0] : emptyUser)
        const st = data.players.filter(p => {
          return p.position === 'ST'
        })
        this.st.push(st[0] ? st[0] : emptyUser)
        if (Number(this.teamFront.training) === 1 || Number(this.teamFront.training) === 3) {
          this.st.push(st[1] ? st[1] : emptyUser)
        }
        this.trainingDescription = data.training_description
        this.bg = data.bg
      })
    }
  }
}
</script>
